import React, {useContext, useEffect,  useState} from "react";
import {useSedECPStore} from "../store/sed_ecp_store";
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import useDoCertsList from "../../../hooks/certList";
import downloadDogovorSign from "../func/downloadDogovorSign";
import ButtonWithicon from "../../../components/shared/ButtonWithicon";
import {UserContext} from "../../../context/UserContext";


function MainPage(props) {
    const {userInfo} = useContext(UserContext)
    const [listSert, setListSert] = useState(null);
    const [errorPlugin,setErrorPlugin] = useState(false)
    const [cert, setSert] = useState(null)
    // const [file,setFile] = useState(null)
    const fileBase64 = useSedECPStore(state=>state.file)
    const fio = useSedECPStore(state=>state.fio)
    // const signature64 = useSedECPStore(state=>state.signature)
    const setSignatureBase64 = useSedECPStore(state=>state.setSignatureBase64)
    const fileName = useSedECPStore(state=>state.fileName)
    // const dogovorSettlement = useSedECPStore(state=>state.dogovorSettlement)



    useDoCertsList(userInfo.company.inn).then((list)=>setListSert(list)).catch(err=>setErrorPlugin("Плагин недоступен. Для подписание ЭЦП установите или проверьте работу CryptoPro WebPlugin"))

    useEffect(()=>{
        console.log(fio)
        console.log(listSert)
        if(Array.isArray(listSert)){
            if(listSert.length === 1) setSert(listSert[0].value)
        } 
    },[listSert])


    return (
        <>
            {(!errorPlugin && fio && listSert) && (
                <Form.Group as={Col} lg={10}>
                    <Form.Label>Выберите сертификат подписи</Form.Label>
                    <Form.Select aria-label="thumbprint select" name="thumbprint"  onChange={e=>{
                        setSert(e.target.value)
                    }}>
                        {
                            listSert.map(item => (
                                <>
                                    {fio.toLowerCase() === item.fio.toLowerCase() && (
                                        <option value={item.value}>{item.label}</option>
                                    )}
                                </>

                            ))
                        }
                    </Form.Select>
                </Form.Group>
            )}
            {(!fileBase64 && !errorPlugin) && (
                <p>Для подписания договора необходимо его сформировать</p>
            )}
            {errorPlugin && (
                <p>{errorPlugin}</p>
            )}

            {(fileBase64 && !errorPlugin && userInfo.company.id.length < 12) && (
                <>
                    <p>{fileName}</p>
                    <ButtonWithicon handle={async ()=>{
                        downloadDogovorSign(setSignatureBase64,cert,fileBase64,fileName)
                    }}>Подписать документ</ButtonWithicon>
                </>
            )}

        </>
    );
}

export default MainPage;