import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import {withStyles} from "@material-ui/core/styles";
import {ReactSVG} from "react-svg"
import style from "./general_menu_style.module.scss"
import {Link} from "react-router-dom"
import './main_menu.scss'
import {SetRollupMenu,SetMenuItemChange} from "../../redux/StateOfStyleObjects"
import {roles} from "../../constants/AuthRole"
import {clientMenu, adminMenu, adminMenuKAM,adminKTSPIncoming} from "../../constants/Menu"
import {UserContext} from '../../context/UserContext'
import instanceServerApi from "../../AxiosInstanse/serverApi";

function ListItemLink(props) {
    const {to, text, type, ...other} = props;
        return (
            <ListItem button component={Link} to={to} {...other} onClick={(e)=>{
                if(type) {
                    e.preventDefault()
                    e.stopPropagation();
                    window.open(to,'_blank')
                }
            }}>
                <ListItemText secondary={text} />
            </ListItem>
        );
}

function ListItemHref(props) {
    const {to, text, menu, id, solovoId,userInfo, ...other} = props;

        return (
            <ListItem  disabled={menu.includes(id) ? false : true} component={'div'}  {...other}
                onMouseDown={(event)=>{
                    event.preventDefault();
                    event.stopPropagation();
                    
                        if(event.button === 0) {
                            instanceServerApi.post('/api/services/solvo/token').then(res=>{
                                window.location.href = `${to}?sso&token=${res.data.response.data.token}&lastname=${userInfo.lastName}&firstname=${userInfo.firstName}&company=${userInfo.company.name}`
                                }).catch(e=>alert("Ошибка при переходе"))
                        }
                        if(event.button === 1) {
                            instanceServerApi.post('/api/services/solvo/token').then(res=>{
                                window.open(to + `?sso&token=${res.data.response.data.token}&lastname=${userInfo.lastName}&firstname=${userInfo.firstName}&company=${userInfo.company.name}`, '_blank');
                                }).catch(e=>alert("Ошибка при переходе"))
                        }
                        if(event.button === 2) {
                          return false
                        }
                        
                    }}
                   
                    >
                <ListItemText secondary={text}/>
            </ListItem>
        );
}


function ListItemLinkCollapse(props) {
    const {to, text, icon, type, rollup, length, ...other} = props;
    if (rollup) {
        return (
            <ListItem style={{height: 48}} button component={Link} to={to} {...other}>
                <ReactSVG className={style.svgIcon} src={icon} wrapper={'span'}/>
            </ListItem>
        )
    } else {
        if(length){
            return (
                <ListItem button  {...other}>
                    <ReactSVG className={style.svgIcon}  src={icon} wrapper={'span'}/>
                    <ListItemText primary={text}/>
                </ListItem>
            );
        } else {
            return (
            <ListItem button component={Link} to={to} {...other}>
                <ReactSVG className={style.svgIcon} src={icon} wrapper={'span'}/>
                <ListItemText primary={text}/>
            </ListItem> )
        }

    }

}

const styles = theme => ({
    root: {
        fontWeight: "500",
        width: "100%",
        
        background: "inherit",

    },
    nested: {
        height: "auto",
        paddingLeft: theme.spacing.unit * 3
    },
    item_icon: {
        minWidth: 18
    },
    gutters:{
        paddingLeft: "8px",
        paddingRight: "8px"
    },
    padding:{

    }
});


class NewGeneralMenu extends React.Component {

    static contextType = UserContext 
    constructor(props){
        super(props)
        this.state ={}
        this.handleClick.bind(this)
    }
    // state = {}
    handleClick = e => {
        for (let key in this.state) {
            if (key !== "rollup")
                this.setState({[key]: false})
        }
        this.setState({[e]: !this.state[e]});
    };

    handelRollUp = () => {
        this.setState({["rollup"]: !this.state["rollup"]})
        this.props.setRollUp(!this.state["rollup"])
    }

    render() {
        const {solvoToken, accessSolvoMenu,userInfo,setSolvo} = this.context

        let items={}
        switch (this.props.role) {
            case roles.client:
            case roles.mainClient:
                {
                items = clientMenu
                break
            }
            case roles.manager: {
                items = adminMenu
                break
            }
            case roles.admin: {
                if(!userInfo.societies) items = adminMenu
                if(userInfo?.societies === null)  items = adminMenu
                if(userInfo?.societies?.length > 0)  items = adminMenuKAM
                break
            }
            case roles.admin_ktsp_incoming: {
                items = adminKTSPIncoming
                break
            }
            default:
                items.list = []
                break
        }

        if(this.props.styleState.menuItemChange!==false){
            this.handleClick(this.props.styleState.menuItemChange)
            this.props.setMenuItem(false)
        }

        const {classes} = this.props;

        return (
            <nav className={style.dashboardMenu}>
                <div className={`${style.rollup_button} ${this.state["rollup"] ? style.rollup_button_rotate : ""}`} onClick={this.handelRollUp}>&lsaquo;</div>

                <div className={!this.state["rollup"] ? "main" : "main_active"}>
                <div style={{height: "calc(100% - 145px)", overflowY: "auto"}}>
                {items.list.map(item => {
                    return (
                        <List
                            className={classes.root}
                            key={item.id}
                        >
                            <ListItem  className={`${this.state[item.title] ? style.listitem_border : ""} `}>
                                    <ListItemLinkCollapse length={item?.items?.length} rollup={this.state.rollup} text={item.title} icon={item.icon}
                                                          type={item.type} to={item.to}
                                                          onClick={()=>{this.handleClick(item.title)}}
                                    />

                            </ListItem>
                            {!this.state["rollup"] && (
                                <Collapse
                                    className={this.state[item.title] ? style.collapse_style : ""}
                                    key={item.id}
                                    component="li"
                                    in={this.state[item.title]}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {item.items && (
                                        <List disablePadding>
                                            {item.items.map(
                                                sitem => {
                                                    if(sitem.type !== "solvo")  return (
                                                        <ListItemLink
                                                            text={sitem.name}
                                                            to={sitem.to}
                                                            type={sitem.type ? sitem.type : false}
                                                            button
                                                            key={
                                                                sitem.id
                                                            }
                                                            className={`${style.link_hover_border} ${classes.nested}`}
                                                        >
                                                        </ListItemLink>
                                                    );
                                                    else{
                                                        if(!solvoToken)   return (
                                                            <ListItemLink
                                                                text={sitem.name}
                                                                to={sitem.notFound}
                                                                button
                                                                key={
                                                                    sitem.id
                                                                }
                                                                className={`${style.link_hover_border} ${classes.nested}`}
                                                            >
                                                            </ListItemLink>
                                                        )
                                                        if(solvoToken) return (
                                                            <ListItemHref
                                                                id={sitem.solvoId}
                                                                menu={accessSolvoMenu}
                                                                text={sitem.name}
                                                                userInfo={userInfo}
                                                                to={`${sitem.to}`}
                                                                // to={`${sitem.to}?sso&token=${solvoToken}&lastname=${userInfo.lastName}&firstname=${userInfo.firstName}&company=${userInfo.company.name}`}
                                                                button
                                                                key={
                                                                    sitem.id
                                                                }
                                                                className={`${style.link_hover_border} ${classes.nested}`}
                                                               
                                                            >
                                                            </ListItemHref>
                                                        )
                                                    }  
                                                }
                                            )}
                                        </List>
                                    )}
                                </Collapse>
                            )}
                            {" "}
                        </List>
                    );
                })}
                  </div>
                <div style={this.state["rollup"] ?{ display: "none"} : {display: "block"}} className={style.description_info}>

                    <List
                        className={classes.root}
                        key={"instructions"}
                    >
                        <ListItem  className={`${this.state['instructions'] ? style.listitem_border : ""} `}>
                            <ListItemLinkCollapse length={4} rollup={this.state.rollup} text={'Инструкции'} icon={'/icons/ico_help.svg'}
                                                  onClick={()=>{this.handleClick("instructions")}}
                            />

                        </ListItem>
                        <Collapse
                            className={this.state['instructions'] ? style.collapse_style : ""}
                            component="li"
                            timeout="auto"
                            in={this.state['instructions']}
                            unmountOnExit
                        >
                            <List disablePadding>
                                <ListItem className={`${style.link_hover_border} ${classes.nested}`} button  component={'div'}  onClick={(e)=>{
                                    window.open('https://konversta.com/ru/help/pass', "_blank")
                                }}>
                                    <ListItemText secondary={"Инструкция Конверста"} />
                                </ListItem>
                                <ListItem className={`${style.link_hover_border} ${classes.nested}`} button  component={'div'}  onClick={(e)=>{
                                    window.open('/instruction.pdf', '_blank', 'fullscreen=yes')
                                }}>
                                    <ListItemText secondary={"Инструкция ЛКК"} />
                                </ListItem>
                                <ListItem className={`${style.link_hover_border} ${classes.nested}`} button  component={'div'}  onClick={(e)=>{
                                    window.open('/instruction_ctsp.pdf', '_blank', 'fullscreen=yes')
                                }}>
                                    <ListItemText secondary={"Инструкция СОЛВО"} />
                                </ListItem>
                                <ListItem className={`${style.link_hover_border} ${classes.nested}`} button  component={'div'}  onClick={(e)=>{
                                    window.open('/Terms_of_use_LKK.pdf', '_blank', 'fullscreen=yes')
                                }}>
                                    <ListItemText secondary={"Соглашения и сертификаты"} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </List>
                    <a href={`mailto:support.lkk@port.one?subject=Запрос на поддержку от  компании ${userInfo.company.name}. ИНН: ${userInfo.company.inn}`} className={style.support_link}>Обращение в поддержку</a>

                    <div className={style.copirate}>
                        © {new Date().getFullYear()}г.<br/>
                        Первая Портовая<br/>
                        Компания
                    </div>

                </div>
            </div>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return {
        styleState: state.styleState,
    }
}

const mapDispatchToProps = dispatch => ({
    setRollUp: (rollup) => dispatch(SetRollupMenu(rollup)),
    setMenuItem: (item) => dispatch(SetMenuItemChange(item))
});
NewGeneralMenu.propTypes = {
    classes: PropTypes.object.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewGeneralMenu));

//
