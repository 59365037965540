import {createContext, useEffect, useState} from 'react';
import { setToken} from "../redux/userSession"
import {useDispatch, useSelector,} from "react-redux"
import {SetUserInfoPopup} from "../redux/StateOfStyleObjects"
import {setCookie,getCookie} from "../AxiosInstanse/autovizitApi"
import {deleteCookie} from "../AxiosInstanse/serverApi"
import instanceServerApi from "../AxiosInstanse/serverApi"
import {setAutomobileDirectory,setGeneralInfoFromILSAR} from "../redux/asyncActions"
import axios from 'axios';
import changeCompanyUser from '../lib/utils/ChangeCompanyForUser'

export const UserContext = createContext({})

const UserProvider = ({children}) => {
    const [userInfo, setUserInfo] = useState(null)
    const token = useSelector(state => state.user.token)
    const loadAutoDirectory = useSelector(state => state.AutoMobileInstanseInfo.load)
    const GeneralInfoLoad = useSelector(state => state.GeneralInfoFromILSAR.load)
    const [solvoToken, setSolvoToken] = useState(false)
    const [accessSolvoMenu, setSolvoMenu] = useState([])
    const [errorLogin, setErrorLogin] = useState(false)
    const [loginUser, setLogin] = useState(null)
    const dispatch = useDispatch()
    
    useEffect(()=>{
        if(userInfo?.role !== "ADMIN" && userInfo?.ilsarId) {
            if (!loadAutoDirectory)
            dispatch(setAutomobileDirectory())
        if (!GeneralInfoLoad)
            dispatch(setGeneralInfoFromILSAR())
        }
        
   
    },[userInfo])

    const setSolvoInfo = (solvoId) =>{
        if(solvoId) {
            instanceServerApi.post('/api/services/solvo/checkUser',{
                login: solvoId
            }).then(res=> {
                if(res.data.response?.data === true) setSolvoToken(true)
            })
            instanceServerApi.post('/api/services/solvo/menu').then(res=> {
                if(res.data.response.activeIds !== null) setSolvoMenu(res.data.response.activeIds)
                else (setSolvoMenu([]))

            }).catch(e=>console.log(e))
        }
        // instanceServerApi.post('/api/services/solvo/token').then(res=>setSolvoToken(res.data.response.data.token)).catch(e=>setSolvoToken(null))


    }

    useEffect(() => {

        const access = getCookie('accessToken')
        if(access === undefined) {
            console.log('access undefined')
            setLogin(false)
            return
        }
        if(token === null) {
            console.log('token null')
            setLogin(false)
            return
        }
        if (token !== null ) {
            instanceServerApi.post(`/api/auth/refresh`, {
                refreshToken: token
            }).then(res => {
                dispatch(setToken(res.data.response.refreshToken))
                setCookie('accessToken',res.data.response.accessToken,1)
                instanceServerApi.get(`/api/profile`).then(async  response => {

                    if(response.data.response.company.id.length > 10) {
                        const profile =  await changeCompanyUser(response.data.response.company,response.data.response)
                        setUserInfo(profile.data.response)
                        setSolvoInfo(profile.data.response.solvoId)
                        setLogin(true)
                    }else{
                        setUserInfo(response.data.response)
                        setSolvoInfo(response.data.response.solvoId)
                        setLogin(true)
                    }

                    // setSolvoInfo(response.data.response.solvoId)
                    // setUserInfo(response.data.response)
                    // setLogin(true)
                })
            }).catch(e =>{
                console.log("Problem with refresh")
                fullLogout()
            })
        }

    }, [])

     

    const login = (props) => {
        if(props.email === undefined ||props.password === undefined){
            setErrorLogin({error: true,text:"Введите логин и пароль"})
            return
        }
        axios.post(process.env.REACT_APP_HTTP_SERVER + `/api/auth/login`, {
            "email": props.email,
            "password": props.password,
            'captcha': props.captcha
        }).then((response) => {
            if(response.data.response.message)  response.data.response.message === "Incorrect captcha" ? setErrorLogin({error: true,text:"Неправильный код с картинки",type: "captcha"}) :setErrorLogin({error: true,text:"Неправильный логин или пароль",type: "login"})
            const authToken = response.data.response
            setCookie('accessToken',response.data.response.accessToken,1)
            dispatch(setToken(authToken.refreshToken))
                instanceServerApi.get(`/api/profile`).then(async response => {
                    if(response.data.response.company.id.length > 10) {
                        const profile =  await changeCompanyUser(response.data.response.company,response.data.response)
                        setUserInfo(profile.data.response)
                        setSolvoInfo(profile.data.response.solvoId)
                        setLogin(true)
                    }else{
                        setUserInfo(response.data.response)
                        setSolvoInfo(response.data.response.solvoId)
                        setLogin(true)
                    }

                    
                })
        }).catch((err,res) => {
            console.log("Ошибка при регистрации")
            err.response.data.response.message === "Incorrect captcha"  ? setErrorLogin({error: true,text:"Неправильный код с картинки",type: "captcha"}) :setErrorLogin({error: true,text:"Неправильный логин или пароль",type: "login"})
            
        })
    }

   const fullLogout = () =>{
        dispatch(SetUserInfoPopup(false))
        setLogin(false)
        dispatch(setToken(null))
        setUserInfo(null)
        setSolvoToken(false)
        setSolvoMenu([])
        deleteCookie('accessToken')
        setErrorLogin(false)
    }

    const logout = () => {
        instanceServerApi.post(`/api/auth/logout`)
        .then(res => {
            fullLogout()
          
        }).catch(err=>{
            fullLogout()
        })
    }

    return (
        <UserContext.Provider value={{ userInfo, solvoToken,login, logout, errorLogin, accessSolvoMenu,loginUser}}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;