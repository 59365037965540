import React, {useEffect, useState} from 'react';
import instanceServerApi from "../../AxiosInstanse/serverApi";
import NotificationsCard from "../../components/start_page_components/NotificationsCard";
import BigTitle from "../../components/FontComponent/BigTitle";
import FlexRow from "../../containers/FlexContainers/FlexRow";

function NotificationsList({open,handleClose}) {
    const[info,setInfo] = useState([])

    useEffect(()=>{
        instanceServerApi.get("/api/other/notifications",{
            params:{
                sort:{"dateIn":"asc"}
            }
        }).then(res=>{
            if(Array.isArray(res.data.response)) setInfo(res.data.response)
        }).catch(err=>{
            console.log(err)
        })
    },[])

    return (
        <>
            <BigTitle>Все уведомления</BigTitle>
            <FlexRow flexDirection={"column"} spacingRow={30} width={"70%"}>
                {info.map(item=>(
                    <NotificationsCard item={item} />
                ))}
            </FlexRow>

        </>

    );
}

export default NotificationsList;